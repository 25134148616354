import i18n from "i18next"
import { initReactI18next } from "react-i18next"

// Funkcija, skirta įkelti vertimus iš WordPress API
const loadTranslations = async () => {
  try {
    const response = await fetch(
      `https://gatsby.lt/gtvlogistics/wp-json/my-translations/v1/get?t=${new Date().getTime()}`
    )
    if (!response.ok) {
      throw new Error("Failed to fetch translations")
    }
    const data = await response.json()
    return data
  } catch (error) {
    console.error("Error loading translations:", error)
    return null
  }
}

// Funkcija nustatyti pradinę kalbą tik kliento pusėje
const initialLanguage = () => {
  if (typeof window !== "undefined" && window.localStorage) {
    const savedLanguage = localStorage.getItem("language")
    return savedLanguage || "lt" // Jei išsaugota kalba nėra, naudoja "lt"
  }
  return "lt" // Naudojama pradinė kalba "lt" serverio pusėje
}

// Funkcija periodiškai tikrinti vertimų atnaujinimus
const startTranslationPolling = (interval = 60000) => {
  setInterval(async () => {
    const updatedResources = await loadTranslations()

    if (updatedResources) {
      console.log("Translations updated.")
      i18n.addResources(updatedResources)
    }
  }, interval) // Nustatome laiko intervalą (pvz., kas 60 sekundžių)
}

// Inicijuojame `i18n` vertimus ir pradedame realaus laiko tikrinimą
loadTranslations().then(resources => {
  if (resources) {
    i18n.use(initReactI18next).init({
      resources,
      lng: initialLanguage(), // Naudojame pradinę kalbą iš localStorage arba numatytąją "lt"
      fallbackLng: "lt",
      interpolation: {
        escapeValue: false, // React jau pasirūpina HTML saugumu
      },
    })
    startTranslationPolling(60000) // Pradeda tikrinti vertimų atnaujinimus kas 60 sekundžių
  } else {
    console.error(
      "Could not initialize i18n because translations failed to load."
    )
  }
})

export default i18n
