import React from "react"
import "./i18n"

export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  const currentPosition = getSavedScrollPosition(location)
  setTimeout(() => {
    window.scrollTo(...(currentPosition || [0, 0]))
  }, 0)
  return false
}

// gatsby-browser.js arba gatsby-ssr.js
export const onRenderBody = ({ setPostBodyComponents }) => {
  setPostBodyComponents([
    <script
      key="twemoji"
      src="https://twemoji.maxcdn.com/v/latest/twemoji.min.js"
      crossorigin="anonymous"
    />,
  ])
}
